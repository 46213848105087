//font-family
$font-family1:'Noe Display'; 
$font-family2: 'Work Sans'; 
$font-family3: 'Miju';


//font-size
$font-size-nano: 0.8rem;
$font-size-micro: 1.0rem;
$font-size-mini: 1.2rem;
$font-size-small: 1.4rem;
$font-size-default: 1.6rem;
$font-size-medium: 1.8rem;//1.125rem
$font-size-large: 2.2rem;
$font-size-big: 2.4rem;
$font-size-bigger: 3.0rem;
$font-size-mega: 3.4rem;
$font-size-giga: 4.5rem;



//font-weight
$font-weight-feather: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-black: 700;

//colors
$black5: #A1A1A1;
$black10: rgba(255, 255, 255, 0.60);
$black40:#8D8FA2;
$black20: #151515; 
$black30: #323232;
$black40: rgba(118, 118, 118, 1);
$black50: #2E2E2E;
$black60:#000;
$white50: #fff;
$white20:#BFBFBF;
$white30:#EBEBEB;
$blue20:#0E1487; 
$blue30: #440E87;
$blue40: rgba(14, 20, 135, 1);
$blue50: #030516;
$glass: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%);;


$shadow1: 0px 0px 5px rgb(0 0 0 / 0.2); //horizontal, vertical, blur color