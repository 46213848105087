



@mixin slideIn($bgcolor, $width, $bgcolor2) {
  border: 1px solid $bgcolor;
  color: $bgcolor;
  box-shadow: inset $width 0 0 0 $bgcolor2;
  transition: box-shadow 0.2s ease-out;
}



@keyframes marquee {
  to {
    transform: translate(-100%);
  }

  from {
    transform: translate(0%);
  }
}


@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}