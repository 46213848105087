@import './variables.scss'; 
@import './accordion.scss';
@import './tab.scss';
@import './responsive.scss'; 
@import './Hamburger.scss'; 
@import './mixins.scss'; 
@import './animation.scss'; 
@import './card.scss'; 
@import './allcard.scss'; 
@import './loader.scss';
@import './badge.scss'; 


@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

@font-face {
  font-family: "Miju";
  src: url('../assets/fonts/Miju/miju-webfont.eot');
  src: 
    url('../assets/fonts/Miju/miju-webfont.eot') format('embedded-opentype'),
      url('../assets/fonts/Miju/miju-webfont.woff2') format('woff2'), 
      url('../assets/fonts/Miju/miju-webfont.woff') format('woff'), 
      url('../assets/fonts/Miju/miju-webfont.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "Noe Display";
  src: url('../assets/fonts/Noe-Display/NoeDisplay-Medium.eot');
  src: url('../assets/fonts/Noe-Display/NoeDisplay-Medium.eot') format('embeded-opentype'), 
    url('../assets/fonts/Noe-Display/NoeDisplay-Medium.woff2') format('woff2'), 
    url('../assets/fonts/Noe-Display/NoeDisplay-Medium.woff') format('woff'), 
    url('../assets/fonts/Noe-Display/NoeDisplay-Medium.ttf') format('truetype'); 
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: $font-family1, $font-family2, $font-family3;
  -webkit-font-smoothing: $font-family1, $font-family2, $font-family3; 
  -moz-osx-smoothing: $font-family1, $font-family2, $font-family3; 
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  line-height: 1.6;
}

.scroll-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  will-change: transform;
}

img {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

hr {
  background-color: $black50;
}

a {
  text-decoration: none;
  color: $black20;
  cursor: pointer !important;
}


h1 {
  font-family: $font-family1;
  font-size: $font-size-giga + 0.5rem;
  font-weight: $font-weight-black;
  line-height: 1.6;


  @media only screen and (max-width: 480px){
    font-size: $font-size-big;
  }
}

h2 {
  font-family: $font-family1;
  font-size: $font-size-giga + 0.5rem;

  @media (max-width: 480px){
    font-size: $font-size-large !important;
  }

  @media (min-width: 381px) and (max-width: 991px) {
    font-size: $font-size-mega + 0.1rem !important;
  }
}

p {
  font-family: $font-family2;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  line-height: 1.7;
  letter-spacing: 0.5px; 
  color: $black30;

  @media (max-width: 480px){
    font-size: $font-size-micro !important;
  }

  @media (min-width: 360px) and (max-width: 767px){
    font-size: $font-size-micro;
  }

  @media (min-width: 381px) and (max-width: 991px) {
    font-size: $font-size-mini;
  }
  
}

ul {
  font-family: $font-family2;
  list-style: none;
}

span, figure {
  font-family: $font-family2;
}

button {
  display: inline-block;
  width: 12rem;
  height: 4rem;
  justify-content: center;
  align-content: center;
  font-family: $font-family2;
  font-size: $font-size-mini;
  font-weight: $font-weight-medium;
  background-color: $black20;
  color: $white50;
  padding: 10px 20px 2px 2px;
  cursor: pointer;
  border: none;

  @media (min-width: 381px) and (max-width: 991px){
    width: 180px;
    height: 60px;
    font-size: $font-size-mini;
    padding: 10px 10px;
  }
}


b {
  font-style: italic;
}


header {
  position: relative;
  background-color: transparent;
  display: block;
  width: 100%;
  height: auto;

  ul {
    li {
      transition: all 0.25s;

      a {
        color: $black40;
        
        &:hover {
          font-weight: bold;
          border-bottom: 2px solid $blue40;
          color: $blue40; 
        }
      }
    }

  }

  &.un-sticky {
    position: relative;
    pointer-events: all !important;
    cursor: pointer !important;
    z-index: 2;
    transition: all 0.25s;
  }

  &.is-sticky {
    position: sticky;
    top: 0;
    box-shadow: 0 0 20px $white30;
    width: 100%;
    z-index: 999;
    cursor: pointer;
    animation: slideDown 0.35s ease-in-out;
    backdrop-filter: blur(20px);
    hr {
      display: none;  
    }

    a {
      color: $black40;
      &:hover {
        color: $blue40;
      }
    }
  }

  hr {
    margin-inline: 2rem;
    opacity: 0.1;
    background-color: rgba(0, 0, 0, 0.05) !important; 
  }


  nav {
    display: flex;
    align-items: center;
    padding-inline: 2rem;
   
  
    ul {
      @include flex-layout(row); 
      margin-left: auto;
    
      li {
        margin-right: 3rem; 
      }
    }

    hr {
      width: 50%;
    }
  }

  .hamburger {
    display: none;
  }


  @media (max-width: 480px){
    overflow-x: auto;
    nav {

      img {
        z-index: 10;
      }

      ul {
        display: none;
      }
    }

    hr {
      display: none;
    }

    .hamburger {
      right: 0;
      display: flex !important;
      margin-left: auto;
    }
  }


  @media (min-width: 381px) and (max-width: 991px) {
    .hamburger {
      display: none;
    }
  }
}


.layout {
  display: flex;
  align-items: center;
  padding-inline: 2rem;
  gap: 7rem;
  height: 100%;
  width: 100%;
  margin-top: 5rem;

  h1 {
    width: 140%;
  }

  .download {
    display: none;
  }

  .btn__container {

    button {
      &:hover, &:focus {
        @include slideIn($black30, 12rem, $white50);
      }
  
      span {
        padding-left: 1rem;
      }
    }

    .mobile__mt {
      margin-left: 2rem;
    }
  }

  @media (min-width: 381px) and (max-width: 991px){
    display: block;
    padding-bottom: 5rem;

    h1 {
      line-height: 1.2;
      padding-top: 5rem;
      font-size: 5rem;
      width: auto;
    }

    .btn__container {
      .mobile__mt {
        margin-left: 2rem;
      }
    }
  }

  @media (max-width: 480px) {
    margin-bottom: -1rem;
    h1 {
      width: auto;
      padding-top: 2rem;
      font-size: $font-size-mega;
      line-height: 1.2;
    }

    display: block;
    width: auto;

    p {
      line-height: 1.4;
    }

    .download {
      display: none;
    }

    button {
      border: 1px solid $black20;
      background: transparent;
      color: $black20;
      width: 100%;
      min-width: 0;
    }


    .btn__container {
      padding-inline-end: 0;
      margin-block: 2rem;

      .mobile__mt {
        margin-top: 2rem;
        margin-left: 0;
      }
    }
  }

  .btn__cloth {
    display: flex;
    justify-content: center;
    align-content: center;
    place-items: center;

    @media (min-width: 381px) and (max-width: 991px){
      height: 0;
    }
  }
}

.hero {
  h2{
    @include space(10rem); 
    text-align: center;
    font-size: 94px; 
    margin-bottom: 0;
    line-height: 1.2;
  }

  div{
    text-align: center;
    margin: auto;
    margin-bottom: 4rem;
    
    .first{
      margin-bottom: 0;
    }

    .second {
      margin: auto;
      width: 60%;
    }


    @media (min-width: 360px) and (max-width: 767px) {
      width: auto;
      padding-inline: 1rem !important;
      
      .first {
        width: 100%;
      }
      
      .second {
        width: 80%;
      }
    }

    @media (min-width: 381px) and (max-width: 991px) {
      padding-inline: 4rem;
      .first {
        text-overflow: ellipsis;
        text-align: center;
        // word-wrap: break-word;
      }
      .second {
        width: 80%;
      }
    } 
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  //MOBILE
  @media (max-width: 480px) {
   @include space(5rem); 

    h2 {
      margin-block: 4rem;
    }

    img {
      max-width: 100%;
    }

    
    h2 {
      font-size: $font-size-large;
      padding-top: 2rem;
    }
  }


  //TABLET
  @media (min-width: 381px) and (max-width: 991px)  {
    height: 100%;
    padding-block: 3rem;
  }
}

.banner {
  padding-inline: 8rem;
  padding-top: 3rem;
  // margin-top: -5rem;
  img {
    width: 100%;
    object-fit: contain;
  }

  

  //MOBILE
  @media (min-width: 360px) and (max-width: 767px) {
    padding-inline: 1rem;
    margin-block-start: 5rem;
    img {
      display: flex;
      flex-direction: row;
      object-fit: fill;
      justify-content: center;
      margin-inline: auto;
      min-width:100%;
      min-height: 100%;
      box-shadow: none;
      margin-top: 0;
    }
  }

  //TABLET
  @media (min-width: 381px) and (max-width: 991px) {
    margin-bottom: 10rem;

    img {
      display: flex;
      justify-content: center;
      justify-items: center;
      margin-inline: auto;
      align-content: center;
      padding: 1rem;
      box-shadow: none;

      width: 100%;
      height: 100%;
    }
  }


  @media (min-width: 400px) and (orientation: portrait){
    padding-bottom: 3rem;
    margin-block: -3rem;
  }
}

.product {
  @include space(10rem); 
  h2 {
    text-align: center;
    margin-bottom: 0;


    @media (min-width: 381px) and (max-width: 991px) {
      padding-bottom: 2rem;
    }
  }

  .feather-left {
    position: absolute;
    left: 0;
    margin-top: 20rem;
    z-index: 1;
  }

  .feather-right {
    position: absolute;
    right: 0;
    z-index: 1;
  }

  .tab-note{
    text-align: center;
    margin: auto;
    .first{
      margin-bottom: 0;
    }

    .second {
      margin: auto;
      width: 55%;
    }
  }

  @media (max-width: 480px){
    margin-block: 5rem;

    h2 {
      padding-block: 2rem;
    }

    p {
      width: 80%;
      text-align: center;
    }

    .panel {
      display: flex;
      margin-inline: auto !important;
      width: 100%;
      height: auto;
      flex-direction: column;
      flex-wrap: wrap;
      img {
        display: flex;
        object-fit: cover;
        min-width: 20rem;
        min-height: 100%;
        margin-top: 3rem;
      }
    }

    
    .panel-container {
      display: flex;
      flex-direction: column;

      .feather-right {
        width: 3.5rem;
        height: auto;
        margin-right: 2rem;
      }

      .feather-left {
        width: 3.5rem;
        height: auto;
        margin-top: 15rem;
        margin-left: -1rem;
      }
    }

  }

  @media (min-width: 381px) and (max-width: 991px) {
    margin-top: 0;

    p {
      margin-bottom: 0;
    }

    .feather-right {
      width: 10%;
      top: 10rem;
      margin-right: 1rem;
    }

    .feather-left {
      left:3rem;
      top: 20rem;
      margin-top: 1rem;
      width: 10%;
    }

    margin-inline: 0;
    .panel {
      // margin-inline: 2rem;
      margin-top: 6rem !important;
    }

    .gallery {
      display: flex;
      justify-content: center;
      height: 38rem;
    }

    img {
      display: flex;
      justify-content: center;
      max-width: 50% !important;
      background-repeat: no-repeat;
      object-fit: contain;
      max-height: auto;
      margin-top: -10rem;
      margin-bottom: 0;
    }
  } 
}

.offers {
  // margin-top: 2rem;
  display: flex;
  align-items: center;
  align-content: center;
  gap: 2rem;
  padding: 10rem 4rem 10rem 4rem;
 

  @media (min-width: 381px) and (max-width: 991px) {
    display: block;
    margin-top: -25rem;
    padding-inline-start: 2rem;
    width: auto;

    p {
      width: 130%;
    }

    nav {  
        display: block;
        margin-top: 0;
    
        h2 {
          font-size: 3rem;
        }
  
        p {
          width: 100%;
        }
    }

    .accordion {
      padding-inline: 0;
      button {
        width: 110%;
      }

      hr {
        margin-inline-start: 0;
        width: 110% !important;
      }
    }
  }

  h2 {
    font-size: $font-size-giga;
    margin-bottom: 1rem;
  }

  .inner {
    width: 80%;
    direction: ltr;
    min-height: 28rem;

    p {
      font-size: $font-size-micro;
    }
    
    .second {
      padding-top: 1rem;
    }

    @media (max-width: 480px){
      width: 100%;
    }

    @media (min-width: 360px) and (max-width: 767px){
      p { 
        width: auto;
      }
      
    }
  }

  @media (max-width: 480px) { 
    display: block;
    margin-top: 0;
    padding-block-end: 4rem;
    padding-block-start: 0;
    padding-inline: 2rem;

    h2 {
      text-align: center;
      font-size: $font-size-medium + 0.8 !important;
    }

    nav {
      h2 {
        font-size: $font-size-bigger;
      }

      p {
        width: 100%;
      }
    }

    .accordion {
      padding-inline: 0;
      button {
        width: 115%;
      }

      hr {
        margin-inline: 0;
        width: 112%;
      }
    }
  }

  .accordion {
    width: 100%;
    min-width: 50%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding-inline-end: 2rem;

    .title {
      font-family: $font-family2 !important;
      font-size: $font-size-bigger !important;
    

      @media (max-width: 480px){
        font-size: $font-size-default !important;
      }
    }

    button {
      .icon {
        width: 3rem !important; 
        height: 3rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      .title {
        font-size: 2rem;
        opacity: 0.8;
        line-height: 1.4;
        font-weight: $font-weight-light;
      }
    }


    hr {
      margin-left: 0;
    }

    @media (min-width: 381px) and (max-width: 991px) {
      padding-inline-start: 0;
      display: flex;
      margin-top: -2rem;

      hr {
        padding-inline-end: 0;
        width: 95%;
      }
    }

    @media(min-width: 400px) {
      margin-top: 0.2rem !important;
    }
  }
}

.story {
  background-color: $blue30;
  color: $white50;
  padding: 4rem 6rem 4rem 6rem;
  align-items: center;
  gap: 5rem;
  height: 100%;
  width: auto;
  display: flex;


  &::before {
    content: '';
    position: absolute;
    background: $glass;
    height: 60%;
    width: 20%;
    margin-left: -14rem;
    transform: rotate(150deg);
    opacity: 0.3;
    flex-shrink: 0;
  }
  

  &::after {
    content: '';
    position: absolute;
    opacity: 0.3;
    background: $glass;
    height: 10%;
    width: 25%;
    margin-left: 20rem;
    transform: rotate(150deg);
  }


  h2 {
    z-index: 1;
    font-size: $font-size-giga + 0.3;
    font-weight: $font-weight-bold;
    font-style: normal;
    line-height: 1.2;
    text-align: left;
    direction: ltr;
    width: 200%;

    @media (min-width: 381px) and (max-width: 991px) {
      width: auto;
    }
  }

  p {
    color: $white20;
    font-weight: $font-weight-normal;
    font-style: normal;
    line-height: 1.4;
  }

  @media (max-width: 480px) {
    display: block;
    width: 100%;
    padding-inline: 1rem;

    h2 {
     width: 100%;
    }


    &::before {
      right: 20%;
      height: 30rem;
      rotate: 45deg;
      margin-top: -5rem;
      perspective-origin: 4rem;
    }

    &::after {
      position: absolute;
      content: '';
      height: 20rem;
      margin-top: -10rem;
      right: 80%;
    }
  }

  @media (min-width: 381px) and (max-width: 991px) {
    padding-inline: 2rem;
    display: block;

    &::before {
      content: '';
      position: absolute;
      background: $glass;
      height: 80%;
      width: 15%;
      right: 95%; 
      rotate: 180deg;
      opacity: 0.3;
    }

    &::after {
      content: '';
      position: absolute;
      background: $glass;
      height:70rem;
      margin-top: -55rem;
      width: 20%;
      right: 50%;
      rotate: 180deg;
      opacity: 0.3;
    }
  }
}

.app-section {
  background-size: contain;
  object-fit: contain;
  height: 150%;
  width: 100%;
  padding: 0;
  color: $white50;

  .cover {
    position: relative;
    display: flex;
    width: 100%;

    img {
      display: flex;
      min-height: 100%;
      min-width: 100%;
      height: auto;
      justify-content: start;
      object-fit: contain;
      background-size: contain;
    }

    .cover-overlay {
      padding-top: 3rem;
      position: absolute;
      max-width: 57%;
      right: 1rem;

      
      h2 {
        font-size: $font-size-giga;
        width: 95%;
        line-height: 1.2;
        font-weight: $font-weight-black;
        text-align: left;
        direction: ltr;
        text-overflow: clip;
      }

      p {
        display: none;
        @media (min-width: 381px) and (max-width: 991px){
          visibility: visible;
          color: $white50;
          display: flex;
          justify-content: end;
          font-size: 24px;
          font-weight: $font-weight-medium;
          font-style: normal;
        }

      }

      .group__btn {
        display: flex;
        margin-left: auto;

        button {
          background: transparent;
          border: 1px solid $white50;
          display: inline-block;
          justify-items: center;
          align-items: center;

          &:hover, &:active {
            @include slideIn($black30, 12rem, $white50)
          }

          span {
            padding-left: 1rem;
            vertical-align: 0.5rem;
          }
        }
      }
    }
  }


    &::before {
      content: '';
      position: absolute;
      background: $glass;
      margin-top: 10rem;
      height: 160rem;
      left: 0;
      margin-left: 12.5rem;
      opacity: 0.3;
      width: 20%;
      transform: rotate(150deg);
      right: 0;
      flex-shrink: 0;
  }



  @media (min-width: 481px) and (max-width: 767px){
    .cover {
      h2 {
        width: auto;
      }
    }
  }

  @media (max-width: 480px){
    .cover {
      position: relative;
      height: auto;
      width: auto; 

      img {
        object-fit: contain;
        min-width: 150%;
        min-height: auto;
      }

      .cover-overlay {
        margin-top: 1rem;
        height: 100%;
        left: 0;
        padding-block: 0;
        min-width: 100%;
        padding-inline: 1rem;
        text-align: end;
        display: flex;
        flex-direction: column;

        p {
          color: $white50;
          margin-left: auto;
          justify-content: end !important;
          min-width: 100%;
          font-size: 1rem;
          margin-bottom: 0.5rem;
          margin-bottom: 0.5rem;
        }
       
        .test {
          height: auto !important;
          min-width: fit-content;
          display: block !important;
         
          h2 {
            font-size: $font-size-default !important;
            direction: rtl;
            margin-left:auto;
            text-align: right;
            width: auto;
          }
        }

        .group__btn {
          padding-top: 0;
          display: flex;
          justify-content: end;

          margin-top: 0.5rem !important;
          button {
            padding-inline: 0;
            display: flex;
            justify-items: center;
            width: 9rem;
            height:3rem;

            .icon {
              width: 40px;
              height: 30px;
            }

            span {
              font-size: 12px;
            }
          }
        }
      }
    }
  }


  @media (min-width: 381px) and (max-width: 991px) {
  
    .cover {
      
      .cover-overlay {
        padding-block-start: 0;
        display: block;

        h2 {
          padding-top: 1rem;
          direction: ltr;
          margin-bottom: 0;
          font-size: $font-size-bigger !important;
          width: 120%;
        }

        p {
          // display: flex;
          justify-content: start;
          margin-block-start: 1rem;
        }

        .group__btn {
          display: flex;
          margin-top: -1rem;
          // padding-bottom: 2rem;
          // justify-content: end;
        }
      }
    }
  }
}

.team {
  background-color: $blue50;
  color: $white50;
  padding: 1rem 3rem 0 3rem;
  height: 58rem;

  @media (min-width: 381px) and (max-width: 991px) {
   height: 65rem;
  }

  h2 {
    text-align: center;
    font-size: $font-size-giga;
    margin-bottom: 0;

    @media (min-width: 381px) and (max-width: 991px){
      font-size: 56px;
      font-weight: $font-weight-bold;
      font-style: normal;
    }
  }

  p {
    color: $white50;
    width: 60%;
    margin: auto;
    text-align: center;
    padding-bottom: 3rem;

    @media (min-width: 381px) and (max-width: 991px){
      width: 70%;
      color: $black10;
      font-size: 20px;
      line-height: 1.4;
    }
  }

  .card__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    padding-top: 4rem;

    @media (min-width: 381px) and (max-width: 991px) {
      padding-top: 1rem;
    }


    .row-container {
      display: flex;
      flex-direction: row;
      gap: 2rem;
  
      .img-container {
        :hover {
          cursor: pointer;
          transform: scale(1);
          transition: .3s ease-in-out; 
          -webkit-transition: .3s ease-in-out;
          -webkit-transform: scale(1.3);
          transform: scale(1.1);
        }
      }
    }
  }




  
  img {
    display: block;
    height: 90%;
    object-fit: contain;
    width: 90%;
    border-radius: 100%;
    filter: grayscale(0.8);
    object-position: center center;
    @media (min-width: 381px) and (max-width: 991px) {
      height: 80%;
      width: 80%;
    }
  }


  @media (max-width: 480px){
    width: 100%;
    height: 100%;
    padding-inline: 0;

    p {
     width: 100%;
     color: $black40;
     padding-inline: 1.5rem;
    }

    .card__container {
      padding-top: 2rem;
      padding-block-end: 2rem;
      
      .row-container {
        display: flex;
        flex-direction: column;

        .img-container {
          width: 100%;
          display: grid;
          justify-items: center;


          ul {
            width: 100%;
            text-align: center;
           padding-inline: 0;
          }
        }
      }

      img {
        width: 60%;
        height: auto
      }
    }
  }
}

.frequently {
  @include space(10rem); 
  h2 {
    font-size: $font-size-giga;
    width: 50%;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    margin-inline: auto;
    text-align: center;

    @media (min-width: 381px) and (max-width: 991px) {
      width: 100%;
      padding-inline: 2rem;
    }

    @media (max-width: 480px){
      width: fit-content;
      padding-inline: 1rem;
      font-size: $font-size-big !important;
    }
  }

  button {
    &:hover, &:active {
      @include slideIn($black30, 12rem, $white50); 
    }

    span {
      padding-left: 1rem;
      vertical-align: 0.5rem;
    }
  }

  .accordion {
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;
    padding-inline: 3rem;

    .answer_wrapper {
      padding-inline-start: 1.5rem;
    }

    button {
      color: $black20 !important;
      padding-block: 1rem;
      .icon {
        width:3rem !important; 
        height: 3rem !important;
        display: flex;
        align-items: center;
        align-content: center;
        align-self: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 480px){
    @include space(5rem);
    .accordion {
      width: 100%;
      min-width: 100%;
      padding-inline: 0;
      padding-bottom: 2rem;
      button {
        display: flex;
        flex-direction: row;
        flex-wrap: row;
        width: 100%;
      }
    }
  }

  @media (min-width: 381px) and (max-width: 991px) {
    .accordion {
      padding-inline: 1rem;
    }
  }

  .overlay-container {
    position: relative;
    display: flex; 
    justify-content: center;
    align-items: start;
    height: 25rem;
    width: auto;

    img {
      display: block;
      width: 85%;
      transform: perspective(400px) scale3d(6);
      min-height: 100%;
      object-fit: contain;
    

      @media (max-width: 480px){    
        display: flex;
        flex-direction: column;
        height: auto;
        object-fit: cover;
        min-height: 120%;
        box-shadow: none;
      }

      @media (min-width: 381px) and (max-width: 991px) {
        box-shadow: none;
        width: 90%;
      }
    }

    .anchor {
      position: absolute;
      left: 25%;
      // padding-left: 5rem;
      padding-bottom: 2rem;
      width: 70%;
      height: 50rem;
      margin-top: 1rem;
      z-index: -1;


      @media (min-width: 360px) and (max-width: 767px){
        display: none;
      }

      @media (min-width: 381px) and (max-width: 1080px){
        display: none;
      }
    }

    .overlay {
      position: absolute;
      width: 100%;
      text-align: center;
      padding-top: 2rem; 

      h2 {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 0;
        margin-inline: auto;
        font-size: 96px;
        font-weight: $font-weight-black;

        @media (max-width: 480px) {
          font-size: $font-size-medium + 0.4 !important;
        }

        @media (min-width: 381px) and (max-width: 991px) {
          font-size: 3.2rem;
          line-height: 1.2;
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }

      p {
        width: 50%;
        display: flex;
        justify-content: center;
        margin-inline: auto;
        font-weight: $font-weight-normal;

        @media (min-width: 360px) and (max-width: 767px){
          line-height: 1.2;
          width: 85%;
          padding: 1rem;
        }

        @media (min-width: 381px) and (max-width: 991px) {
          font-size: 1.2rem;
          text-overflow: ellipsis;
        }
      }
      
      button {
        margin-left: 2rem;
        border: 1px solid $black50;
        background: transparent;
        color: $black50;
      }


      @media (max-width: 480px){
        .group__btn {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          align-items: center;

          button {
            width: 12rem;
            height: 3rem;
            margin-left: 0;

            &:last-of-type {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
}


.blog {
  padding: 4rem 4rem 6rem 4rem;
  width: 100%;

  .blog-intro {
    @include flex-layout(row); 
    align-items: center;


    p {
      width: 50%;
      margin-left: auto; 
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .flex-images {
    @include flex-layout(row); 
    gap: 2rem;

    img {
      width: 25rem; 
      height: auto; 
    }
  }

  @media (max-width: 480px){
    padding: 1.5rem 1rem 3rem 1rem;

    .blog-intro {
      display: flex;
      flex-direction: column;
      align-items: start;
     

      h2 {
        width: 100%;
        height: 2rem;
      }

      p {
        width: 100%;
        padding-block-end: 1rem;
        margin-top: 0 !important;
        direction: ltr;
      }
    }


    .flex-images {
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      // mask: linear-gradient(20deg, transparent, $black20 10%, $black20 85%, transparent);
      // -webkit-mask: linear-gradient(90deg, transparent, $white50 50%, $white50 85%, transparent);
      
      img {
        width: 20rem; 
        height: auto
      }
    }
  }

  @media (min-width: 381px) and (max-width: 991px) {
    padding: 2rem 2rem 4rem 2rem;
    .blog-intro {
      @include flex-layout(column);  

      h2 {
        justify-items: start;
        justify-content: flex-start;
        align-content: start;
        margin-inline-end: auto; 
        height: 1rem;
      }

      p {
        width: 100%; 
        padding-block: 1.5rem;
      }
    }

    .flex-images {
      overflow-x: scroll; 
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
    }
  }
}


footer {
  @include space(20rem); 

  .mobile__logo {
    display: none;
  }

  @media (min-width: 381px) and (max-width: 991px) {
    margin-top: 5rem;
  }

  @media (max-width: 480px){
    @include space(0); 
  }

  section {
    padding-inline: 4rem;
    display: flex;
    
    @media (max-width: 480px){
      padding-inline: 1rem;
      padding-block-start: 15rem;
      padding-block-end: 0;
      margin-bottom: 1rem;
      display: block;
      height: 100%;

      img {
        width: 5rem;
        background-size: contain;
        height: 5rem;
      }

      h3 {
        font-size: $font-size-micro;
      }

      .btn__group {
        display: flex;
        
        button {
          display: flex;
          align-items: center;
          padding-inline: 0;
          font-size: 14px;
          width: 12rem;
          height: 3rem;
        }
      }
    }

    @media (min-width: 381px) and (max-width: 991px) {
      display: block;
    }

    .footer__identity {
      display: block;
      // background-color: red;
      width: 100%;
      margin-top: 8rem;

      @media (min-width: 381px) and (max-width: 991px){
        display: block;
      }

      button {
        margin-right: 2rem;
        background: transparent;
        border: 1px solid $black20;
        color: $black50;

        &:hover {
          @include slideIn($white50, 12rem, $black50)
        }

        span {
          padding-left: 1rem;
          vertical-align: 0.5rem;
        }
      }

      @media (min-width: 360px) and (max-width: 767px){
        padding-bottom:0;
        margin-top: -6rem;
        .btn__group {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1rem;
          button {
            display: flex;
            width: 15rem;
            height: 3rem;
          }
        }

      }
    }

    .footer__links {
      @include flex-layout(row); 
      flex-wrap: wrap;
      margin-top: 3rem;
      margin-left: auto;
      a {
        color: $black40;
        
        &:hover {
          font-weight: bold;
          border-bottom: 2px solid $blue40;
          color: $blue40; 
        }
      }
     
      .accordion {
        display: none;
      }

      @media (min-width: 360px) and (max-width: 767px){

        .accordion {
          width: 100%;
          display: block;
          padding-block: 0;
          padding-inline: 0;
          
          button {
            background-color: transparent;
            color: $black50;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            
          }

          .title {
            font-size: $font-size-micro;
            color: $black30;
            display: flex;
            justify-content: start;
          }

          .icon {
            color: $white30;
            display: flex;
            justify-content: end;
            margin-left: auto;
          }

          hr {
            color: $white30;
          }
        }
      }


      @media (min-width: 381px) and (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .link__container {
        display: flex;
        
        ul {
          margin-inline: 1.5rem;
        }



        ul {
          @media (min-width: 360px) and (max-width: 767px){
            display: none;
          }
          padding-top: 5rem;          
          span {
            font-weight: $font-weight-bold;
          }
          li {
            margin-top: 1.5rem;
          }
        }

        @media (min-width: 381px) and (max-width: 991px){
          display: flex;
          justify-content: space-between;
          width: 100%;
          ul {
            margin-inline-start: -2.5rem;
          }
        }
      }
    }

    h3 {
      font-size: $font-size-mini;
      font-weight: $font-weight-light;
    }
  }


  .footer-description{
    
    ul {
      display: flex;
     
      @media (min-width: 381px) and (max-width: 991px) {
        visibility: hidden;
      }
      li {
        padding-left: 2rem;
      }
    }


    @media (max-width: 480px){
      ul {
        display: none;
      }
      
    }
  }

  .section__ledger {
    padding: 6rem 1rem 0 3rem; 

    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: 480px){
      padding-inline: 1rem;
      padding-block: 0;

      img {
        display: none;
      }

      .mobile__logo {
        display: block;
        height: 100%;
        padding-block-end: 0;
        h1 {      
          width: auto;
          margin-block-end: 2rem;  
          font-family: $font-family3;    
          font-weight: $font-weight-normal;
          font-style: normal;
          color: #151515;
          font-size:8.5rem;
          opacity: 0.4;
          letter-spacing: -2.2%;
          line-height: 0.2;
          -webkit-text-stroke: 1px gray;
          -webkit-text-fill-color: white;
          text-shadow: 
            0 0 0 $black30, 
            0 0 0 $black30, 
            0 0 0 $black30, 
            0 0 0 $black30, 
            -2px 2px 0 $black30,
            -1px -2px 0 $black30,
            -1px -1px 0 $black30,
            -1px 0px 0 black,
            -1px 1px 0 black,
            -1px 2px 0 black,
            0px -2px 0 black,
            0px -1px 0 black,
            0px 0px 0 black,
            0px 1px 0 black,
            0px 2px 0 black,
            1px -2px 0 black,
            1px -1px 0 black,
            1px 0px 0 black,
            1px 1px 0 black,
            1px 2px 0 black,
            2px -2px 0 black,
            2px -1px 0 black,
            2px 0px 0 black,
            2px 1px 0 black,
            2px 2px 0 black
        }
      }
    }

    @media (min-width: 381px) and (max-width: 991px) {
      padding-top: 0;
    }
  }
}



img {
  background-size: contain;
  cursor: pointer;
}