
@import './variables.scss'; 

.card {
  display: grid;
  grid-template-columns: 2fr;

  img {
    width: 25rem;
    height: auto;
  }

  .date {
    font-size: $font-size-micro;
  }

  h3 {
    font-size: $font-size-small;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      color: $blue40;
    }
  }

  a {
    cursor: pointer;

    &:hover {
      font-weight: bold;
      border-bottom: 2px solid $blue40;
      color: $blue40; 
    }
  }
}