

/** SMALL TABLET */
@media (min-width: 381px) and (max-width: 991px) {
  body {
    word-break: length($list: 2);
  }









  h2 {
    font-size: 56px;
    font-style: $font-weight-normal;
    font-weight: $font-weight-bold;
    line-height: 1.2;
  }

  button {
    display: flex;
  }

}