

.tabs {
  position: relative;
  display: flex;


  @media (max-width: 480px){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .tab-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: scale(1.4);
    border-bottom:2px solid $white30;
    color: $black5;
    font-size: $font-size-small;
    
  
    &:focus {
      color: $blue20;
      border-bottom: 2px solid $blue20;
    }

    
    &:active {
      border-bottom: 2px solid $blue20;
      color: $blue20;
    }
  }


  @media (max-width: 480px){
    div {
    
      p { 
        text-align: center;
        display: block;
        text-align: center;
        margin-inline: auto;
        
        &:first-of-type{
          width: 60%;
        }
      }
    }
  }

  .panel {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 6rem 4rem 2rem 4rem;
    width: fit-content;

    @media (max-width: 480px){
      padding-top: 0;
    }


    @media only screen and (max-height: 575.98px) and (orientation: landscape){
      margin-bottom: 20rem !important;  
    }

    .panel-container {
      display: flex;
      column-gap: 2rem;
      margin-top: 0;

      @media (max-width: 400px) and (orientation: portrait) {
        margin-block: auto ;
      }

      @media only screen and (max-height: 575.98px) and (orientation: landscape) {
        gap: 4rem ;
        justify-content: center;
        padding-inline: 1rem;
        width: 100%;
        margin-top: 1rem;

        img {
          min-width: 15rem ; 
          min-height: 18rem;
        }
      }
    } 

    @media (min-width: 360px) and (max-width: 767px){
      position: relative;
    }


    @media (min-width: 381px) and (max-width: 991px){
      margin-top: 3rem !important;
      margin-bottom: 5rem;
      height: 50vh;
      width: auto;
    }

    img {
      position: relative;
      max-width: 50%;
      height: 100%;
    }
  }
}
