


.badge {
  height: 2rem;
  width: auto;
  color: #EC70F2;
  border: 1px solid #EC70F2;
  border-radius: 50px;
  padding: 0.4rem;
  font-size: 0.8rem;
  display: grid;
  place-content: center;


  @media (max-width: 480px) {
    font-size: 0.4rem;
    height: 1.4rem;
  }
}