@import './mixins.scss'; 


.hamburger {

  @include btn(310px, 100px);
  cursor: pointer;

  .burger-icon {
    @include icon(4px, 30px, 15px);
    z-index: 10;

    &::before {
      @include icon(4px, 30px, -10px);
    }

    &::after {
      @include icon(4px, 30px, 10px);
    }
  }

  &.open {
    .burger-icon {
      transition-duration: 0.5s;
      background: transparent;

      &::before {
        transform: rotateZ(45deg) scaleX(1) translate(6.5px, 6.5px);
      }

      &::after {
        transform: rotateZ(-45deg) scaleX(1) translate(6px, -6px);
      }
    }
  }
  
  &:hover {
    cursor: pointer;
  }


  .hamburger-links-container {
    background-color:$white50;
    z-index: 1;
    width: 100%; 
    position: fixed;
    left: 0;
    top: 0;
    height: 30rem;


    ul {
      z-index: 10;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-top: 4rem;
      padding-inline: 0;

      li {
        display: flex;
        width: 100%;
        color: $black20;
        justify-content: space-between;
        align-content: center;
        align-self: stretch;
        align-items: center;
      }

      hr {
        display: block;
        margin-inline: 0;
        border: 1px solid $white30;
        width: 100%;
      }
    }

    &.change {
      display: none;
    }

    .hamburger-links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding-inline: 2rem;
      padding-block: 2rem;
    }


    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding-top: 2rem;
      position: relative;
      width: 55%;

      &:active {
        color: $blue30;
      }
    }
  }


}