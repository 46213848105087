

.accordion{
  ul {
    color: $black50;
    height: 100%;
    display: flex; 
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    box-sizing: content-box;
  
    li {
      display: block;

    }

    .answer {
      @media (max-width: 480px){
        height: 4rem;
        max-width: auto !important;
        padding-inline-end: 0;
      }
    }

  
    button {
      background: transparent;
      border: 0 !important;
      color: $black50;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      height: 100%;
      width: 100%;
      
      .title {
        display: flex;
        align-items: center;
        justify-content: start;
        color: $black60;
      }
  
      .icon {
        background: transparent;
        color: $black50;
        width: 100%;
        height: auto;
        border: 1px solid $black50;
        border-radius: 50%;
        background: transparent;
        margin-left: auto;
        display: flex;
        justify-content: center;
        padding-inline: 0;
      }


      @media (max-width: 480px){
        font-size: $font-size-micro;
        font-weight: $font-weight-normal;
        text-align: left;
        display: flex;
        justify-content: center;
        align-content: center;
        height: auto;

        .icon {
          display: flex;
          width: 2rem !important;
          display: flex !important;
          justify-content: center !important;
          padding-inline: 0;
        }

        .title {
          display: flex;
          font-size: $font-size-micro !important;
        }
      }


      @media (min-width: 381px) and (max-width: 991px) {
       display: flex;
       .icon {
        display: flex;
        width: 2rem !important;
        height: auto;
        padding-inline: 0;
        justify-content: center;
       }
      }
  
    }
  
    .answer_wrapper {
      width: 100%;
      height: 2rem; 
      overflow: hidden;
      transition: height 250ms ease-out;
      padding-inline-start: 0.5rem;
      opacity: 0.5;
    }
  
    hr {
      margin-inline: 1.5rem;
    }
   
  }
}

