
@mixin btn($left, $right){
  position: absolute;
  width: 60px;
  height: 60px;
  top: 30px;
  left: $left;
  transition-duration: 0.5s;
}


@mixin icon($height, $width, $top){
  transition-duration: 0.5s;
  position: absolute;
  width: $width;
  height: $height;
  background-color: $black20;
  content: "";
  top: $top;
}


@mixin flex-layout($direction){
  display: flex;
  flex-direction: $direction;
}

@mixin space($space){
  margin-top: $space
}