




.loader {
  position: fixed;
  padding: 20px;
  inset: 0;
  margin: auto;
  width: max-content;
  height: max-content;
  // top: 40%;
  // left: 0;
  // transform: translate(calc(50vw - 50%));
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%); //x, y axis
}